body{
    font-family: 'Varela Round';
}

h1{
    font-size: 6em;
    padding-top: 0px !important;

}


h1, h2, h3, h4, h5 {
    font-family: 'Varela Round';
    font-weight: 700;
}

h3{
    margin-bottom: 0;
}

h6{
    font-size:1.5em;
    font-family: 'Varela Round';
    font-weight: 700;
    text-transform: none;
}
h6::before{
    display: inline-block;
    content: "";
    border-top: 1px solid lightgray;
    width: 100%;
    margin: 10px 0px 0px 0px;
    transform: translateY(-1rem);
}


.subHeadline{
    padding-top:-40px;
    font-size:1.5em;
}

.subHeadlineYellow{
    color:yellow;
    font-weight: 800;
    padding-top:100px;
    font-size:1.6em;
}

.description, .card-description, .footer-big p {
    color:black;
    font-weight: 400;
}

.stat{
    
    height: 975px;
    background-image: url("../img/back1.png");
    width:'100%' !important;
    position: relative;
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover; /* Resize the background image to cover the entire container */
}

.north{
    background-image: url("../img/north.png");
    min-height: 435px;
    overflow: hidden;
    position: relative;
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover; /* Resize the background image to cover the entire container */
}

.navbar .navbar-nav .nav-link:not(.btn){
    font-size: 1.2em;
    font-weight: 900;
}

.bg-info{
    /* background-color: #FFFFFF !important; */
    background:rgba(255,255,255,0.8) !important;
}

.nav-link{
    cursor: pointer;
}
/* a{
    color:black;
    font-size: 20px;
    font-weight: 700;
} */

/* a:hover{
    color:black;
    font-size: 20px;
    font-weight: 700;
} */

.join{
    width:'100%' !important;
    background-color:#F6F4F4;
}

.logos{
    margin-top:60px;
    margin-bottom:60px;
}

.yearMenu{
    color:black;
    font-size: 20px;
}

.selyearMenu{
    color:"#FF3D67";
    font-size: 30px;
    font-weight: 600;
}

.selYearMenuMeny{
    color:#FF3D67 !important;
    font-weight: 600;
    font-size: 20px;
}
.contentMenu{
    color:#ff9900 !important;
    font-weight: 600;
    font-size: 20px;
    margin-top: 32px;
}

.title
{
    text-align: left;
    padding-top: 300px;
    font-weight: 900;
    font-size: 4.5em;
    text-shadow: 2px 2px rgb(107, 103, 103);

}

.titleClassRoom{
    padding-top: 300px;
    font-weight: 900;
    font-size: 4em;
}

.title-first{
    font-weight: 600;
    padding-top: 30px
}

.navbar .navbar-toggler {
    width: 37px;
    height: 27px;
    outline: 0;
    cursor: pointer;
   
}

.estimated{
    color: #b4b4b4;
    font-weight: 800;
    margin: auto;
    width: 100%;
}


.navbar a:not(.btn):not(.dropdown-item) {
    color: black !important;
}

.content-center{
    text-align: center;
}

.bg-britta{
    padding-top: 50px !important;
    padding-bottom: 50px !important;
    background-color: #ff9900;
}

.bg-black h2 {
    color: white;
}

.bg-black h3 {
    color: white;
}

.bg-white{
    padding-bottom: 3em;
}

.bg-white h3
{
    color: black;
}

.bg-search{
    background-color: #F3F3F3;
}


.bg-grey{
    padding-top:30px;
    background-color: #F3F3F3;
}

.bg-red-top{
    padding-top:130px;
    background-color: #66cccc;
}


.bg-black{
    padding-top:30px;
    background-color: black;
}

.first-row{
    padding-top: 3em;
}

.bigball{
    padding-bottom: 2em;
    width: 250px;
}

.about{
    font-family: 'Varela Round';
    padding-bottom: 3em;
}

.navi{
    background-color: #B91F60;
    width: 100%;
    color:white;
    text-align: center;
    font-size: 2em;
    font-weight: 800;
    font-family: 'Varela Round';
    padding-top: 15%;
    padding-bottom: 15%;
    margin-top: 1em;
    border-radius: 15px;
}

.v2{
    background-color: rgb(56, 56, 56) !important;
    font-size: 1em;
}

.v2lessgrey{
    background-color: rgb(133, 128, 128) !important;
}

.human{
    background-color: #dc3300;
}
.city{
    background-color: #ff9900;    
}
.sea{
    background-color: #66cccc;   
}
.nature{
    background-color: #8cc73f;    
}

.cloud{
    background-color: #FF6600;
}
a {
    /* color: white !important; */
    text-decoration: none !important;
}

@media only screen and (max-width: 600px) {

    .stat{
        text-align: center;
    }

    .ball180{
        margin-top:10px;
    }

    .balls{
        margin-top:10px;
        text-align: center;
        padding-bottom: 20;
    }

    .north{
        display: none;
    }
    
}

.menuTopic_sea{
    font-family: 'Varela Round';
    list-style-type: none;
    font-size: 1.2em;
    color: #66cccc !important;
    font-weight: 900;
    margin-top:20px;
}

.roundedTitel_sea {
    background-color: #66cccc;
    color: white;
    padding-left: 25px;
    padding-top: 30px;
    border-radius: 15px;
    margin-bottom: 20px;
    
}


.menuTopic_nature{
    font-family: 'Varela Round';
    list-style-type: none;
    font-size: 1em;
    color: #8cc73f !important;
    font-weight: 900;
    margin-top:20px;
}

.roundedTitel_nature {
    background-color: #8cc73f;
    color: white;
    padding-left: 25px;
    padding-top: 30px;
    border-radius: 15px;
    margin-bottom: 20px;
    
}

.menuTopic_city{
    font-family: 'Varela Round';
    list-style-type: none;
    font-size: 1em;
    color: #ff9900 !important;
    font-weight: 900;
    margin-top:20px;
}


.roundedTitel_city {
    background-color: #ff9900;
    color: white;
    padding-left: 25px;
    padding-top: 30px;
    border-radius: 15px;
    margin-bottom: 20px;
    
}

.menuTopic_human{
    font-family: 'Varela Round';
    list-style-type: none;
    font-size: 1em;
    color: #dc3300 !important;
    font-weight: 900;
    margin-top:20px;
}

.menuTopic_YiOhjxIAAC4As72P{
    font-family: 'Varela Round';
    list-style-type: none;
    font-size: 1em;
    color: #dc3300 !important;
    font-weight: 900;
    margin-top:20px;
}

.roundedTitel_human {
    background-color: #dc3300;
    color: white;
    padding-left: 25px;
    padding-top: 30px;
    border-radius: 15px;
    margin-bottom: 20px;
    
}
/* 
.menuLesson a:before{
    content: "";
    width: 12px;
    border-top: 1px solid #333;
    margin-top: 10px;
    position: absolute;
    left: 0;
} */
.menuLesson{
    margin-top: 15px; 
}

.menuLesson a{
    font-family: 'Varela Round';
    color: #3e3e3e !important;
    font-size: 0.8em !important;
    font-weight: 100;
    padding-top: 10px;
    /* margin-left: 15px; */
}
.menuLesson a:hover{
    font-weight: 900;
}


.lesson-content{
    border-left: 1px solid #ececec;
    
}


.roundedTitel {
    
    color: black;
    padding-left: 0px;
    padding-top: 10px;
    font-size: 1.8em;
}

.lesson-content h3{
   margin-top: 20px;
   margin-bottom: 20px;
}

.lesson-content ul li{
    font-family: "Montserrat", "Helvetica Neue", Arial, sans-serif;
    font-size: 1em;
    line-height: 1.61em;
    font-weight: 300;
    font-size: 1.2em;
}

.btn-success{
    background-color: #f96332;
    color: white;
    font-size: 14px;
    border-radius: 8px;
}

.btn-cta-classroom{
    background-color: #ff9900;
    font-weight: 900;
    color: white;
    font-size: 18px;
    border-radius: 8px;
    padding: 10px;
}


.btn-success:hover{
    background-color: #441c0f;
    color: white;
}

.btn-primary{
    border-radius: 15px;
    font-size: 14px;
}

.btn-secondary{
    border-radius: 15px;
    font-size: 14px;
}

.embed-youtube iframe{
    width: 100%
}

.lessonTitle{
    color: #b4b4b4
}

.dividerVertical{
    color: #b4b4b4;
    border-left: 1px #F6F4F4 solid;
    border-right: 1px #F6F4F4 solid;
    opacity: 0.3;
}


.topicIcon{
    margin-right: 20px;
}


.page-header-small{
    min-height: 800px !important;
}

.page-header-mini{
    min-height: 500px !important;
}

.leftMenu{
    position: -webkit-sticky;
    position: sticky;
    top: 6rem;
}

.gg-file-document {
    --ggs: 1;
    display:inline-block; white-space: nowrap;
    color: lightgray;
    margin-left: 4px;
 }

 .gg-folder-add {
    --ggs: 1;
    display:inline-block; white-space: nowrap;
    vertical-align:middle;
 }

 .gg-printer {
    --ggs: 1;
    display:inline-block; white-space: nowrap;
    vertical-align:middle;
 }

 .gg-trash {
    --ggs: 1;
    display:inline-block; white-space: nowrap;
    vertical-align:middle;
    margin-right: 10px;
 }

 .gg-mail {
    --ggs: 1;
    display:inline-block; white-space: nowrap;
    vertical-align:middle;
   
 }

 .gg-copy {
    --ggs: 1;
    display:inline-block; white-space: nowrap;
    vertical-align:middle;
    margin-right: 10px;
 }

 .gg-folder {
    --ggs: 1;
    display:inline-block; white-space: nowrap;
    vertical-align:middle;

 }


 .gg-file-add {
    --ggs: 1;
    display:inline-block; white-space: nowrap;
    vertical-align:middle;
    margin-right: 5px;
 }


 .gg-chevron-double-right {
    --ggs: 1;
    display:inline-block; white-space: nowrap;
    vertical-align:middle;
 }

 .gg-chevron-double-left {
    --ggs: 0.2em;
    display:inline-block; white-space: nowrap;
    vertical-align:middle;
 }
 
 .gg-readme {
    --ggs: 1;
    display:inline-block; white-space: nowrap;
    vertical-align:middle;
    margin-right: 10px;
 }

 .gg-time {
    --ggs: 1;
    display:inline-block; 
    white-space: nowrap;
    vertical-align:middle;
 }

 .gg-comment{
     margin-left: -30px;
     margin-top: 20px;
    --ggs: 2;
    display:inline-block; 
    white-space: nowrap;
    vertical-align:middle;
 }

 .gg-pen{
    margin-left: -30px;
     margin-top: 20px;
    --ggs: 2;
   display:inline-block; 
   white-space: nowrap;
   vertical-align:middle;
}

.gg-film{
    margin-left: -30px;
     margin-top: 20px;
    --ggs: 2;
   display:inline-block; 
   white-space: nowrap;
   vertical-align:middle;
}


.gg-link{
    --ggs: 0.7;
    display:inline-block; 
    white-space: nowrap;
    vertical-align:middle;
}

.gg-arrow-left-r{
    --ggs: 0.9;
    display:inline-block; white-space: nowrap;
    color: lightgray;
    margin-left: 0px;
}

.gg-smile{
    --ggs: 2;
}



.copyLink {
    color: darkgrey !important
}

.meLink{
    color: darkgrey !important
}


.card:hover {
    cursor: pointer;
    /* transform: rotate(0deg) scale(1.1) translateY(10px);
    transition: .5s all ease; */
    z-index: 400;
  }

.media-body{
    margin: 20px 20px 20px 0px
}

.media img{
    border-radius: 15px;
}

.introImage{
    border-radius: 15px;
}


.goRight{
    position: absolute;
    right: 20px;
    margin-top: 0px;
}

.inputField{
    padding-top: 10px;
    padding-bottom: 10px;
}

.page-header-mini .content-center{
    top: 60% !important
}

.Toaster__alert_text{
    font-family: 'Varela Round' !important;
}

.leftMenuMe{
    border-right: 1px solid #ececec;
}

.lesson-content a{
    color: #2c2c2c !important
}

.contentLink{
    color: #2c2c2c !important
}

.back a{
    color: #b4b4b4 !important;
    
}

.back {
    font-size: 0.6em;
    padding-bottom: 2em;
}
.back.col{
    font-size: 14px !important;
    color: rgb(28, 97, 96)
}
.back.col a{
    font-size: 14px !important;
    color: rgb(28, 97, 96)
}

.classroom h2{
    font-size: 3em;
    font-family: 'Fredoka One', sans-serif;
    color: white!important
}


.classroom h4{
    font-size: 1.5em;
    font-family: 'Fredoka One', cursive;
    color: white!important
}

.classroom h3{
    font-family: 'Varela Round', cursive;
    color: white!important
}

.classroom p{
    font-family: 'Varela Round', cursive;
    color: white!important
}

.bg-black p{
    font-family: 'Varela Round', cursive;
    color: white!important
}


.coverflow__container__1P-xE {
    background: rgba(0, 0, 0, 0) !important
}

.coverflow__figure__3bk_C{
    box-shadow: 0px 0px 0px rgba(0, 0, 0, 0) !important;
    -webkit-box-reflect: below 0px -webkit-linear-gradient(bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0) 20%, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0)) !important
}

.coverflow__cover__25-7e{
    box-shadow: 0px 0px 0px rgba(0, 0, 0, 0) !important
}

.coverflow__text__39hqd {
    background: rgba(0, 0, 0, 0) !important
}

.bg-green{
    padding-top:50px;
    background-color: black;
    padding-bottom:30px;
}

.bg-skrapipedia{
    padding-top:50px;
    background-color: #009c9c;
    padding-bottom:50px;
}

.bg-rapporten{
    padding-top:50px;
    background-color: #ffffff;
    padding-bottom:50px;
}

.bg-inspiration{
    padding-top:50px;
    background-color: black;
    padding-bottom:30px;
}

.bg-rapporten h2{   
    color: black;
}

.blackText{
    color: black !important;
}

.headline{
    font-size: 3em;
    font-family: 'Fredoka One', sans-serif;
}

.levelFinish{
    font-size: 3em;
    font-family: 'Fredoka One', sans-serif;
    color: white;
}

.whiteText{
    color: white !important;
}

.skrapipedia-master{
    background-color: black;
}

.skrapipedia-master h5{
    font-family: 'Luckiest Guy', cursive;
    margin-top: 2em;
    font-size: 2.5em;
    color: white !important
}

.skrapipedia-master h2{
    
    margin-top: 2em;
    color: #D21F71 !important
}

.skrapipedia-master p{
    color: white !important
}

.skrapipedia-master .level{
    margin-top: 2em;
    color: #FF5F22 !important;
    font-family: 'Press Start 2P', cursive;
    font-size: 4em;
}

.skrapipedia-master .gameName{
    margin-top: 2em;
    color: #0DE439 !important;
    text-decoration: underline
}

.skrapipedia-master .readySet .one{
    font-family: 'Fredoka One', cursive;
    color: #E10001 !important;
    font-size: 60px;
}


.skrapipedia-master .readySet .two{
    font-family: 'Fredoka One', cursive;
    color: #F1CB16 !important;
    font-size: 60px;
}
.skrapipedia-master .readySet .three{
    font-family: 'Fredoka One', cursive;
    color: #0DE439 !important;
    font-size: 60px;
}

.leftColumnGame{
    border-right: white 1px solid;

}

.correctMe a{
    margin-top: 100px;
    font-family: 'Fredoka One', cursive;
    color: #0DE439 !important;
    font-size: 40px;
    margin-bottom: 100px;
}

.correctMeWrong a{
    margin-top: 100px;
    font-family: 'Fredoka One', cursive;
    color: red !important;
    font-size: 40px;
    margin-bottom: 100px;
}

.cc-selector input{
    margin:0;padding:0;
    -webkit-appearance:none;
       -moz-appearance:none;
            appearance:none;
}
.visa{background-image:url(http://i.imgur.com/uKnMvyp.png);}
.mastercard{background-image:url(http://i.imgur.com/uKnMvyp.png);}

.cc-selector input:active +.drinkcard-cc{opacity: .9;}
.cc-selector input:checked +.drinkcard-cc{
    -webkit-filter: none;
       -moz-filter: none;
            filter: none;
}
.drinkcard-cc{
    cursor:pointer;
    background-size:contain;
    background-repeat:no-repeat;
    display:inline-block;
    width:100px;height:70px;
    -webkit-transition: all 100ms ease-in;
       -moz-transition: all 100ms ease-in;
            transition: all 100ms ease-in;
    -webkit-filter: brightness(1.8) grayscale(1) opacity(.7);
       -moz-filter: brightness(1.8) grayscale(1) opacity(.7);
            filter: brightness(1.8) grayscale(1) opacity(.7);
}
.drinkcard-cc:hover{
    -webkit-filter: brightness(1.2) grayscale(.5) opacity(.9);
       -moz-filter: brightness(1.2) grayscale(.5) opacity(.9);
            filter: brightness(1.2) grayscale(.5) opacity(.9);
}

.chat{
    /* border: 1px white solid; */
    /* padding:10px; */
    border-radius: 15px;
    margin-bottom: 10px;
}

.chatBox{
    background-color: white !important;
}

.chat .card{
    border-radius: 20px;
   
}

.chat .card-body{
    
    /* padding-bottom: 10px !important;
    margin-bottom: 40px !important; */
}

.chat .card-body:hover{
    transition: none ;
    transform: none !important;
}

/* .card:hover {
    cursor: pointer;
    transform: rotate(0deg) scale(1.1) translateY(10px);
    transition: .5s all ease;
    z-index: 400;
  } */


  .thumbnail{
    border-radius: 20px !important;
    border: 0px !important;
  }

  .yourTime{
    opacity: 0.5;
    font-family: 'Fredoka One', sans-serif;
  }
  
  .fa-home{
    margin: 0;
    margin-left: 10%;
    font-size: 30px;    
  }

  .fa-gamepad{
    margin: 0;
    font-size: 30px;    
  }

  .fa-film{
    margin: 0;
    margin-left: 20%;
    font-size: 30px;    
  }

  .fa-book-open{
    margin: 0;
    margin-left: 12%;
    font-size: 30px;    
  }

  .fa-print{
    font-size: 30px;   
    cursor: pointer;
  }

  .printMe{
    font-size: 30px;   
    cursor: pointer;
    
  }

  .startOver{
    font-size: 30px;   
    background-color: #FF3D67;
    cursor: pointer;
    border-radius: 25px;
  }

  .answerCorrect{
    font-family: 'Fredoka One', sans-serif;
    font-size: 20px;
  }

  .questionHeader{
    font-family: 'Fredoka One', sans-serif;
    font-size: 28px;
    font-weight: 900;
  }

  .questionDesc{
    font-size: 14px;
  }

  .leftMenuIcon{
    font-size: 13px !important;
    margin-left: 0;
    color: #ff9900;
    opacity: 0.6;
  }

  .menuLink {
    color: #000000 !important;
  }

 .slick-dots{
     bottom: -150px;
     margin-bottom: 100px;
 }

 .slick-next:before {
    color: "#000" !important;
    opacity: 1;
 }


 .video-wrapper iframe{
     border: 0;
     border-radius: 25px;
 }

 .logoTop{
     margin-left: 280px;
 }

 .indexButton{
     border: 0px;
     background-color: white;
 }
.thumbnail {
     width:300px !important;
     height:300px !important;
 }

 .image_picker{
    left: 8% !important;
    position: relative;
 }
 .printTest{
    -webkit-print-color-adjust: exact;
 }

@media print
{    
    .no-print, .no-print *
    {
        display: none !important;
    }
}

.image_picker .selected .checked{
    height: 0px;
    width: 0px;
    display: hidden;
    visibility: hidden;
}



.homeBox{
    border-radius: 20px;
    background: #F5F5F5;
    padding: 20px;
    width: 100%;
    /* height: 150px; */
    margin-bottom: 1em;
}
.homeBoxPlast{
    border-radius: 20px;
    background: #3b5b61;
    padding: 20px;
    width: 100%;
    /* height: 150px; */
    margin-bottom: 1em;
}

.homeBoxChat{
    border-radius: 20px;
    background: #019D9C;
    padding: 20px;
    width: 100%;
    /* height: 150px; */
    margin-bottom: 1em;
}

.homeBoxSkoj{
    border-radius: 20px;
    background: #FF9A00;
    padding: 20px;
    width: 100%;
    /* height: 150px; */
    margin-bottom: 1em; 
    background: rgb(255,154,0);
    /* background: linear-gradient(180deg, rgba(255,154,0,1) 0%, rgba(255,154,0,1) 24%, rgba(255,211,0,1) 100%); */
}

.homeBoxRandom{
    border-radius: 20px;
    background: #FF6600;
    padding: 20px;
    width: 100%;
    /* height: 150px; */
    margin-bottom: 1em;
    
}

.homeBoxGoingOn{
    border-radius: 20px;
    background: #D9D4D0;
    padding: 20px;
    width: 100%;
    /* height: 150px; */
    margin-bottom: 1em;
    background: rgb(217,212,208);
    /* background: linear-gradient(180deg, rgba(217,212,208,1) 16%, rgba(119,119,119,1) 100%); */
    
}


.knep{
    border-radius: 20px;
    padding: 20px;
    width: 100%;
    /* height: 150px; */
    margin-bottom: 1em;
    background: #c6ecec;
    
}

.fun{
    border-radius: 20px;
    padding: 20px;
    width: 100%;
    /* height: 150px; */
    margin-bottom: 1em;
    background: #ff9900;
    
}

.homeBoxBritta{
    border-radius: 20px;
    background: #319C9C;
    padding: 20px;
    width: 100%;
    /* height: 150px; */
    margin-bottom: 1em;
}

.homeBoxWhatIs{
    border-radius: 20px;
    background: #319C9C;
    padding: 20px;
    width: 100%;
    /* height: 150px; */
    margin-bottom: 1em;
}

.homeBoxPinned{
    border-radius: 20px;
    background: #f3f3f3;
    padding: 20px;
    width: 100%;
    /* height: 150px; */
    margin-bottom: 1em;
}

.homeTitlePlast{
    font-size: 1.8em;
    color: white;
}

.homeTitle1{
    font-size: 1.8em;
    color: #1C6160;
}

.homeTitle2{
    font-size: 1.8em;
    color: #FF9A00;
}

.homeTitle3{
    font-size: 1.8em;
    color: #FF6600;
}

.homeTitle4{
    font-size: 1.5em;
    color: #111111;
}


.homeTitle5{
    font-size: 1.8em;
    color: #fff;
}



.circular {
    width:100px;
    height:100px;
    border-radius:50%;
    
    background-position: center;
    background-size:100% auto;
    background-repeat: no-repeat;
  }
.circular img {
    width:100px;
    height:100px;
    border-radius:50%;
   
    background-position: center;
    background-size:100% auto;
    background-repeat: no-repeat;
  }


  .ey{
    left: 42% !important
  }

  .ey h1{
    font-size: 6em
  }

  .form-control {
    background-color: transparent;
    border: 1px solid #E3E3E3;
    /* border-radius: 30px; */
    color: #2c2c2c;
    height: 50px;
    line-height: normal;
    font-size: 0.8571em;
    transition: color 0.3s ease-in-out, border-color 0.3s ease-in-out, background-color 0.3s ease-in-out;
    box-shadow: none;
    border-radius: 5;
}

.card, .card-img-top {
    border-radius: 20px 20px 20px 20px;
}

.form-control{
    font-size: 18px;
}


.simple-trans-main > div {
    cursor: pointer;
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-weight: 800;
    font-size: 25em;
    will-change: transform, opacity;
    text-shadow: 0px 2px 40px #00000020, 0px 2px 5px #00000030;
  }

  .embed{
      max-width:300 !important;
      background-color: "red";
      border-radius: 20px
  }

  a{
    cursor: pointer !important;
  }

  ul, li {
    line-height: 1.61em;
    font-weight: 300;
    font-size: 1.1em;
  }


#navbar-brand-mobile{
    display: none
}

.mobile-menu{
    display: none
}

  /* Portrait and Landscape */
@media only screen 
and (min-device-width: 375px) 
and (max-device-width: 667px) 
and (-webkit-min-device-pixel-ratio: 2) { 

    .title {
        font-size: 4em !important;
        padding-left:20px
      }

      #navbar-brand-mobile{
          display: none
      }


        .mobile-menu{
            display: block
        }
        .desktop-logo{
            display: none
        }

}

.navbar-toggler {
    color: "#000";
    background-color: "red" !important;
}

.navbar .navbar-toggler {
    width: 57px;
    height: 47px;
    outline: 0;
    cursor: pointer;
}
